import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { AuthService } from '@core/data-access/services/auth.service';
import { MenuKeys, SectionType, UpdateAction } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { Tenant } from '@core/models';
import { ViewportService } from '@core/services/viewport.service';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import { PlaceAdFacade } from '@feature/place-ad/place-ad.facade';
import { MatchingTenantsFacade } from '@shared/facades/matching-tenants-facade';
import { DestroyService } from '@shared/services/destroy.service';
import { Observable, catchError, filter, finalize, takeUntil, tap, throwError } from 'rxjs';

@Component({
	templateUrl: './place-ad-submitted.page.html',
	styleUrls: ['./place-ad-submitted.page.scss'],
	providers: [DestroyService],
})
export class PlaceAdSubmittedPage implements OnDestroy, OnInit {
	constructor(
		public viewport: ViewportService,
		private placeAdFacade: PlaceAdFacade,
		private router: Router,
		private auth: AuthService,
		private route: ActivatedRoute,
		private destroy: DestroyService,
		private matchingTenanstFacade: MatchingTenantsFacade,
		private matchingTenantsFacade: MatchingTenantsFacade,
		private commonFacade: CommonFacade,
		private readonly profileFacde: MyProfileFacade
	) {
		this.userEmail = auth.user().email;
		this.tenantMatches$ = this.matchingTenantsFacade.matchingTenantsList$;
	}

	userEmail: string;
	isDisableSchedule = false;
	isPaymentUnderProcess = false;
	readonly appRoute = APP_ROUTE;
	readonly sectionType = SectionType;
	loading = true;
	sellerType = '1';

	tenantMatches$: Observable<Tenant[]>;

	ngOnDestroy(): void {
		this.placeAdFacade.setIsCompletAdSubmitted(false);
	}

	checkPaymentSuccesfull() {
		const QUERY_PARAMS = this.route.snapshot.queryParams;
		const SESSION_ID = QUERY_PARAMS['sessionId'];
		const AD_ID = QUERY_PARAMS['adId'];

		if (SESSION_ID && AD_ID) {
			this.loading = true;
			const REQUEST_BODY = { sessionId: SESSION_ID, adId: AD_ID };

			this.isPaymentUnderProcess = true;
			const API_REQUEST$ = this.matchingTenanstFacade.verifyPayment(REQUEST_BODY);

			API_REQUEST$.pipe(
				tap(() => this.profileFacde.updateProfileValues(MenuKeys.ADDS_COUNT, UpdateAction.ADD)),
				finalize(() => {
					this.isPaymentUnderProcess = false;
					this.loading = false;
				})
			).subscribe();
		}
	}

	onScheduleTenantView(tenantData: Tenant): void {
		this.commonFacade.loadAvailiblitySlots();

		const AD_ID = this.route.snapshot.queryParams['adId'] ? this.route.snapshot.queryParams['adId'] : this.placeAdFacade.getAdId();

		this.matchingTenanstFacade
			.getMatchingTenantDetail(AD_ID, tenantData?.id)
			.pipe(
				catchError((error) => {
					this.isDisableSchedule = false;
					throw new Error(error);
				})
			)
			.subscribe((res: { data: Tenant }) => {
				this.isDisableSchedule = false;
				this.matchingTenanstFacade.openTenantViewDateDialog(res?.data, AD_ID, 3);
			});
	}

	//to load tenant list after payment complete if its coupon payment
	private checkForTenantList(): void {
		if (this.route.snapshot.queryParams['adId'] && !this.route.snapshot.queryParams['sessionId']) {
			this.loading = true;
			this.profileFacde.updateProfileValues(MenuKeys.ADDS_COUNT, UpdateAction.ADD);
			this.matchingTenanstFacade
				.loadTenantList(this.route.snapshot.queryParams['adId'], '3')
				.pipe(
					catchError((err) => {
						this.matchingTenanstFacade.setMatchingTenantsList([]);
						this.loading = false;
						return throwError(err);
					})
				)
				.subscribe(() => {
					this.loading = false;
				});
		}
	}

	ngOnInit(): void {
		this.sellerType = this.placeAdFacade.getSellerTypeId();

		this.checkPaymentSuccesfull();
		this.checkForTenantList();

		this.router.events
			.pipe(
				filter((event) => {
					return event instanceof NavigationStart;
				}),
				takeUntil(this.destroy)
			)
			.subscribe((event: NavigationStart) => {
				//navigate to place-ad if user clicks back button
				if (event.navigationTrigger === 'popstate') {
					this.router.navigate([this.appRoute.home]);
				}
			});
	}
}
