import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { PlaceAdSubmittedPage } from './place-ad-submitted.page';
import { PlaceAdSubmittedRoutingModule } from './place-ad-submitted-routing.module';
import { MatchingTenantItemModule } from '@feature/general/matching-tenant-item/matching-tenant-item.module';
import { RentPropertiesSubmittedModule } from '@feature/place-ad/components/rent-properties-submitted/rent-properties-submitted.module';
import { SalePropertiesSubmittedModule } from '@feature/place-ad/components/sale-properties-submitted/sale-properties-submitted.module';
import { SetTenantViewDateDialogModule } from '@feature/general/set-tenant-view-date-dialog/set-tenant-view-date-dialog.module';
import { MatchingTenantsFacade } from '@shared/facades/matching-tenants-facade';
import { MatchingTenantsState } from '@shared/states/matching-tenants-state';
import { TenantViewDateSummaryDialogModule } from '@feature/general/tenant-view-date-summary-dialog/tenant-view-date-summary-dialog.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
	declarations: [PlaceAdSubmittedPage],
	imports: [
		SharedModule,
		PlaceAdSubmittedRoutingModule,
		MatchingTenantItemModule,
		RentPropertiesSubmittedModule,
		SalePropertiesSubmittedModule,
		SetTenantViewDateDialogModule,
		TenantViewDateSummaryDialogModule,
		MatProgressSpinnerModule,
	],

	providers: [MatchingTenantsFacade, MatchingTenantsState],
})
export class PlaceAdSubmittedModule {}
