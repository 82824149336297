<ng-container *ngIf="!loading ; else skeletonLoader">
	<ng-container *ngIf="{tenantMatches : tenantMatches$| async} as vm">
		<ng-template [ngTemplateOutlet]="vm?.tenantMatches?.length>0 ? propertiesForRent : propertiesForSale"></ng-template>

		<ng-template #propertiesForRent>
			<findqo-rent-properties-submitted [userEmail]="userEmail" [sellerType]="sellerType">
				<!-- To show tenant matches if any -->
				<div class="row justify-content-start gap-3">
					<div class="col-sm match-tenant" *ngFor="let item of vm.tenantMatches; let i=index">
						<findqo-matching-tenant-item
							[index]="i+1"
							[tenantData]="item"
							(openTenant)="onScheduleTenantView($event)"
							[isDisableScheduleBtn]="isDisableSchedule"
						></findqo-matching-tenant-item>
					</div>
				</div>
			</findqo-rent-properties-submitted>
		</ng-template>

		<ng-template #propertiesForSale>
			<findqo-sale-properties-submitted [userEmail]="userEmail" [sellerType]="sellerType"></findqo-sale-properties-submitted>
		</ng-template>
	</ng-container>
</ng-container>

<ng-template #skeletonLoader>
	<div class="container my-3 position-relative h-100 w-100">
		<div class="spinner">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</ng-template>
