<div class="container px-3 py-4 h-100">
	<div class="row align-items-start py-4 px-3 heading-bg gap-4">
		<div class="col-sm-auto">
			<img [ngSrc]="'assets/images/place-an-ad/ad-submitted-illus-desktop.webp'" width="125" height="87" alt="Ad submitted" fetchPriority="high" />
		</div>

		<div class="col-sm">
			<h2 class="heading-2 fg-general-5 mb-2">{{ 'n3114' | translations }}</h2>

			<p class="text-body-regular fg-general-3">
				{{ 'n3115' | translations }}
				<span class="text-body-medium">{{ userEmail }}</span>
			</p>
		</div>
	</div>

	<mat-divider class="divider-100px bg-prim-2 mx-auto my-4"></mat-divider>

	<h4 class="fg-general-5 heading-4-medium">{{ 'n3646' | translations }}</h4>

	<p class="fg-general-4 text-body-regular link-text mb-4" [innerHTML]="'n3663' | translations | linkify: { translation: 'n3663' }"></p>

	<ng-content></ng-content>

	<mat-divider class="divider-100px bg-prim-2 mx-auto my-4"></mat-divider>

	<button mat-button class="text-body-regular fg-general-4 d-block mx-auto" (click)="handleMoreTenants()">{{ 'n3664' | translations }}</button>
</div>
