import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { PlaceAdFacade } from '@feature/place-ad/place-ad.facade';
import { APP_ROUTE } from '@core/constants';

@Injectable({
	providedIn: 'root',
})
export class PlaceAdSubmitGuard {
	constructor(private readonly placeAdFacade: PlaceAdFacade, private router: Router) {}

	// to prevent user from going to place ad submit page   manually without completion of ad
	canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const IS_AD_SUBMITTED = this.placeAdFacade.getIsCompleteAdSubmitted();

		if ((IS_AD_SUBMITTED && route.queryParams['adId']) || (route.queryParams['adId'] && route.queryParams['sessionId'])) {
			return true;
		} else {
			this.router.navigate([APP_ROUTE.wildCard], { skipLocationChange: true });
			return false;
		}
	}
}
