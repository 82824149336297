import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlaceAdSubmittedPage } from './place-ad-submitted.page';
import { RouteDataType } from '@core/services/route.service';
import { PlaceAdSubmitGuard } from '@core/guards/place-ad/place-ad-submit.guard';

const routes: Routes = [
	{
		path: '',
		component: PlaceAdSubmittedPage,

		data: {
			[RouteDataType.IS_HEADER_VISIBLE]: false,
			[RouteDataType.IS_FOOTER_VISIBLE]: false,
		},
		canActivate: [PlaceAdSubmitGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PlaceAdSubmittedRoutingModule {}
