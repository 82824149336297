import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { SellerType } from '@core/enums';

@Component({
	selector: 'findqo-rent-properties-submitted',
	templateUrl: './rent-properties-submitted.component.html',
	styleUrl: './rent-properties-submitted.component.scss',
})
export class RentPropertiesSubmittedComponent {
	constructor(private readonly router: Router) {}

	@Input() userEmail: string;
	@Input() set sellerType(id: string) {
		if (id === SellerType.INDIVIDUAL) {
			this.myAdsRoute = APP_ROUTE.myAdList;
		}
	}

	myAdsRoute = APP_ROUTE.agentHub.dashboard;
	readonly appRoute = APP_ROUTE;

	handleMoreTenants(): void {
		this.router.navigate([this.myAdsRoute]);
	}
}
