import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TenantViewDateSummaryDialogComponent } from './tenant-view-date-summary-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
	declarations: [TenantViewDateSummaryDialogComponent],
	imports: [SharedModule, MatDialogModule],
	exports: [TenantViewDateSummaryDialogComponent],
})
export class TenantViewDateSummaryDialogModule {}
