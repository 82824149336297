import { Component, Input } from '@angular/core';
import { APP_ROUTE } from '@core/constants';
import { SellerType } from '@core/enums';

@Component({
	selector: 'findqo-sale-properties-submitted',
	templateUrl: './sale-properties-submitted.component.html',
	styleUrl: './sale-properties-submitted.component.scss',
})
export class SalePropertiesSubmittedComponent {
	@Input() userEmail: string;

	@Input() set sellerType(id: string) {
		if (id === SellerType.INDIVIDUAL) {
			this.myAdsRoute = APP_ROUTE.myAdList;
			this.homePageRoute = APP_ROUTE.home;
		}
	}

	myAdsRoute = APP_ROUTE.agentHub.dashboard;
	homePageRoute = APP_ROUTE.agentHub.dashboard;
}
