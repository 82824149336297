<div class="container mx-auto text-center px-3">
	<img [ngSrc]="'assets/images/place-an-ad/ad-submitted-illus-mobile.webp'" alt="Ad submitted" class="d-sm-none position-relative" fill />

	<img [ngSrc]="'assets/images/place-an-ad/ad-submitted-illus-desktop.webp'" alt="Ad submitted" fill class="d-none d-sm-block position-relative" />
	<h2 class="heading-2 fg-general-5 mt-4 mb-3">{{ 'n3114' | translations }}</h2>

	<p class="text-body-regular fg-general-3">{{ 'n3115' | translations }}</p>

	<p class="bg-general-1 email-container">
		<span class="text-body-regular fg-general-5 text-center">
			<mat-icon class="email-icon">mail_outline</mat-icon>
			{{ userEmail }}
		</span>
	</p>

	<button class="text-small-14px-regular w-100 h-100 d-block" mat-flat-button color="primary" [routerLink]="['/', myAdsRoute]" type="submit">
		{{ 'n3116' | translations }}
	</button>

	<button class="text-small-14px-regular w-100 h-100 mt-3 fg-general-4 d-block" mat-button color="primary" [routerLink]="['/', homePageRoute]" type="submit">
		{{ 'n3117' | translations }}
	</button>
</div>
