import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { SalePropertiesSubmittedComponent } from './sale-properties-submitted.component';

@NgModule({
	declarations: [SalePropertiesSubmittedComponent],
	imports: [SharedModule],
	exports: [SalePropertiesSubmittedComponent],
})
export class SalePropertiesSubmittedModule {}
