import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SetTenantViewDateDialogComponent } from './set-tenant-view-date-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [SetTenantViewDateDialogComponent],
	imports: [SharedModule, MatDatepickerModule, MatButtonToggleModule, MatDialogModule, FormsModule, ReactiveFormsModule],
	exports: [SetTenantViewDateDialogComponent],
})
export class SetTenantViewDateDialogModule {}
