import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { RentPropertiesSubmittedComponent } from './rent-properties-submitted.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
	declarations: [RentPropertiesSubmittedComponent],
	imports: [SharedModule, MatDividerModule],
	exports: [RentPropertiesSubmittedComponent],
})
export class RentPropertiesSubmittedModule {}
